import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation, booleanAttribute, input } from '@angular/core';
import type { ApiTypes } from '@hyundai/ng-common-lib';
import { RouterLinkDirective } from '@hyundai/ng-common-lib';
import { M000C010ImageComponent } from '../../../000-primitives/m000-c010-image/m000-c010-image.component';

@Component({
    selector: 'ui-m030-c060-mobile-contact-person',
    standalone: true,
    imports: [M000C010ImageComponent, RouterLinkDirective, NgClass],
    templateUrl: './m030-c060-mobile-contact-person.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class M030C060MobileContactPersonComponent {
    contactPerson = input.required<{
        name: string;
        image: { url: string; alt?: string };
        position: string;
        phone: string;
    }>();
    primaryButton = input<{ label: string; link: string }>();
    secondaryButton = input<{ label: string; link: string }>();
    dealer = input.required<ApiTypes.Dealer>();
    isFirstView = input.required({ transform: booleanAttribute });
}
