import { ChangeDetectionStrategy, Component, ViewEncapsulation, booleanAttribute, input } from '@angular/core';
import type { ApiTypes } from '@hyundai/ng-common-lib';
import { RouterLinkDirective } from '@hyundai/ng-common-lib';
import { M000C010ImageComponent } from '../../000-primitives/m000-c010-image/m000-c010-image.component';
import { M010C010IconDoubleCtaComponent } from '../../010-buttons-and-links/m010-c010-icon-double-cta/m010-c010-icon-double-cta.component';
import { M010C020IconLinkComponent } from '../../010-buttons-and-links/m010-c020-icon-link/m010-c020-icon-link.component';
import { M010C025IconLinkExternalComponent } from '../../010-buttons-and-links/m010-c025-icon-link-external/m010-c025-icon-link-external.component';
import { M040C010EnvkvComponent } from '../../040-content-structure/m040-c010-envkv/m040-c010-envkv.component';

@Component({
    selector: 'ui-m060-c030-vehicle-teaser',
    standalone: true,
    templateUrl: './m060-c030-vehicle-teaser.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        RouterLinkDirective,
        M000C010ImageComponent,
        M010C020IconLinkComponent,
        M010C010IconDoubleCtaComponent,
        M010C025IconLinkExternalComponent,
        M040C010EnvkvComponent,
    ],
})
export class M060C030VehicleTeaserComponent {
    image = input.required<{
        url: string;
        alt?: string | null;
    }>();
    imageCaption = input<string | null>();
    text = input.required<string>();
    ctaLink = input.required<string>();
    ctaText = input.required<string>();
    ctaSubText = input.required<string>();
    link = input.required<string>();
    linkText = input.required<string>();
    primaryButton = input.required<string>();
    primaryButtonText = input.required<string>();
    externalLink = input<string>();
    externalLinkText = input<string | null>();
    shortInfos = input<Array<{ value: string; unit: string; label: string }>>();
    showOnlyPrimaryButton = input(false, { transform: booleanAttribute });
    envkv = input<{ model: string; subheadline?: string; envkv: ApiTypes.Envkv; score: string }>();
    isFirstView = input.required({ transform: booleanAttribute });
}
