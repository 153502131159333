<a class="group block" [hyundaiRouterLink]="data().link">
    <div class="mb-8 flex aspect-square items-center justify-center bg-grey-100">
        @if (data().image; as imageData) {
            <ui-m000-c010-image
                class="block overflow-hidden"
                [classList]="'image-hover w-full aspect-square'"
                [image]="{
                    image: imageData.url,
                    desktop: { width: 262, height: 262 },
                    tablet: { width: 232, height: 232 },
                    mobile: { width: 351, height: 351 },
                }"
                [priority]="isFirstView()"
                [alt]="imageData.alt || data().headline"
                cover
            />
        } @else {
            <i class="icon-logo size-1/3 text-white md:size-64 md:text-64"></i>
        }
    </div>
    <p class="text-16 leading-27 text-black">{{ data().headline }}</p>
    <div class="mt-4 flex w-full items-center justify-between gap-8">
        <p class="font-headline text-16 font-medium leading-27 text-black">
            @if (data().price) {
                {{ data().priceLabel }} {{ data().price }} EUR
            }
        </p>
        <i class="icon-chevron-right text-16 text-black group-hover:animate-bouncingLeft"></i>
    </div>
</a>
