@if (contactPerson(); as person) {
    <!-- (Mobile) | Contact Person (eg. for Home) -->
    <ui-m030-c060-mobile-contact-person
        [dealer]="dealer()"
        [contactPerson]="person"
        [primaryButton]="primaryButton()"
        [secondaryButton]="secondaryButton()"
        [isFirstView]="isFirstView()"
    />
}

<!-- (Desktop) | Content over Content (eg. for Home) -->
<!-- (Mobile) | Content over and below Content (eg. for Home) -->
<ng-content />

<section class="py-[32px] md:pb-64 md:pt-40" [ngClass]="[darker() ? 'bg-grey-500' : 'bg-grey-100']">
    <div class="inner-page-wrapper grid grid-cols-1 md:grid-cols-2 md:gap-x-40 lg:grid-cols-3 xl:gap-x-56">
        <!-- (Mobile) | DealerInfo für Name, Street und About us Link -->
        <ui-m030-c060-dealer-info class="block md:hidden" [dealer]="dealer()" [showAboutUsLink]="showAboutUsLink()" />

        <div class="mt-[28px] md:mt-0">
            <!-- (Desktop) | Contact Person (eg. for Home) -->
            @if (contactPerson(); as person) {
                <ui-m030-c060-desktop-contact-person
                    [contactPerson]="person"
                    [phonenumber]="dealer().phone"
                    [isFirstView]="isFirstView()"
                />
            }

            <!-- (Desktop & Mobile) | OpeningHours -->
            <div [ngClass]="{ 'bg-white p-12 md:bg-transparent md:p-0': contactPerson() }">
                <h2 class="text-16 leading-27 text-black">Unsere Öffnungszeiten:</h2>
                <h3 class="-mt-4 text-14 leading-27 text-black">
                    <span class="capitalize">{{ dealer().openings.overallNextOpening }}</span>
                    von {{ dealer().openings.overallRange?.start }} bis {{ dealer().openings.overallRange?.end }}
                </h3>

                <div class="mt-8 flex flex-col gap-16">
                    @if (dealer().openings.sales.openings.length > 0) {
                        <ui-m030-c060-department-opening [openings]="dealer().openings.sales.openings" text="Verkauf" />
                    }
                    @if (dealer().openings.service.openings.length > 0) {
                        <ui-m030-c060-department-opening
                            [openings]="dealer().openings.service.openings"
                            text="Service"
                        />
                    }
                    @if (dealer().openings.parts.openings.length > 0) {
                        <ui-m030-c060-department-opening
                            [openings]="dealer().openings.parts.openings"
                            text="Zubehör/Teile"
                        />
                    }
                </div>
            </div>
        </div>

        <div>
            <!-- (Desktop) | DealerInfo für Name, Street und About us Link -->
            <ui-m030-c060-dealer-info
                class="hidden md:block"
                [dealer]="dealer()"
                [showAboutUsLink]="showAboutUsLink()"
            />

            <!-- (Desktop & Mobile) | Hinweis: Offizieller Hyundai Promise Partner -->
            @if (dealer().hPromise) {
                <div
                    class="px-12 py-4 md:max-w-[261px]"
                    [ngClass]="[contactPerson() ? 'mt-2 md:mt-12' : 'mt-12', darker() ? 'bg-grey-100' : 'bg-grey-500']"
                >
                    <p class="leading-21 text-14 text-black">
                        Geprüfte Gebrauchtwagen:
                        <br />
                        Wir sind offizieller
                        <b>Hyundai Promise</b>
                        Partner.
                    </p>
                    <a
                        class="leading-21 text-14 font-medium text-accent"
                        [hyundaiRouterLink]="dealer().pages.hyundaiPromise"
                    >
                        Mehr erfahren
                    </a>
                </div>
            }
        </div>

        <div class="col-span-1 mt-24 md:col-span-2 lg:col-span-1 lg:mt-0">
            <!-- (Desktop & Mobile) |  Quick Links -->

            <ui-m030-c060-quick-links
                [dealer]="dealer()"
                [contactLink]="contactLink()"
                (contactClicked)="contactClicked.emit()"
            />

            <!-- (Desktop) | Primary and Secondary Button -->
            @if (primaryButton() || secondaryButton()) {
                <ui-m030-c060-desktop-buttons
                    [primaryButton]="primaryButton()"
                    [secondaryButton]="secondaryButton()"
                    (secondaryButtonClicked)="secondaryButtonClicked.emit()"
                    (primaryButtonClicked)="primaryButtonClicked.emit()"
                />
            }
        </div>

        @if (locations().length > 0) {
            <div class="mt-24 border-t-1 border-grey-500 pt-24 md:col-span-2 lg:col-span-3 lg:border-transparent">
                <p class="mb-12 font-headline text-16 font-medium leading-27">Unsere weiteren kompetenten Standorte:</p>
                <div
                    class="grid grid-cols-2 gap-x-16 gap-y-[36px] md:grid-cols-3 md:gap-x-24 md:gap-y-48 lg:grid-cols-4 lg:gap-x-56"
                >
                    @for (location of locations(); track location.id) {
                        <ui-m040-c050-card
                            [headline]="location.city"
                            [image]="location.menuImage ?? { url: '' }"
                            [link]="location.aboutUsUrl"
                            [isFirstView]="!hasNgContent() && isFirstView()"
                            flushContent
                        />
                    }
                </div>
            </div>
        }
    </div>
</section>
