<div class="flex grid-cols-2 flex-nowrap items-center justify-center gap-24 *:text-center lg:grid">
    @if (dealer().googleMapsLink; as link) {
        <a
            ui-m010-c020-icon-link
            class="inline-block"
            [hyundaiRouterLink]="link"
            [mobileLabelBelow]="true"
            icon="icon-route"
            target="_blank"
        >
            Standort
        </a>
    }
    <a
        ui-m010-c020-icon-link
        class="inline-block"
        [hyundaiRouterLink]="contactLink()"
        [mobileLabelBelow]="true"
        (click)="contactClicked.emit()"
        icon="icon-contact"
    >
        Kontakt
    </a>
    <a
        ui-m010-c020-icon-link
        class="inline-block"
        [hyundaiRouterLink]="'tel:' + dealer().phone"
        [mobileLabelBelow]="true"
        icon="icon-phone"
    >
        <span class="hidden md:block">{{ dealer().phone }}</span>
        <span class="block md:hidden">Anrufen</span>
    </a>
    <a
        ui-m010-c020-icon-link
        class="inline-block"
        [hyundaiRouterLink]="dealer().pages.aboutUs"
        [mobileLabelBelow]="true"
        fragment="Unser Team"
        icon="icon-team"
    >
        Unser Team
    </a>
</div>
