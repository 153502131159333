<div class="inner-page-wrapper py-24 md:py-40">
    <div class="grid grid-cols-1 bg-grey-100 md:grid-cols-2">
        <div class="relative">
            @if (firstBadge(); as badge) {
                <ui-m040-c030-badge class="absolute right-16 top-16 z-10" type="accent">{{ badge }}</ui-m040-c030-badge>
            }
            @if (secondBadge(); as badge) {
                <ui-m040-c030-badge class="absolute left-16 top-16 z-10" type="white">{{ badge }}</ui-m040-c030-badge>
            }
            <ui-m000-c010-image
                class="block h-full overflow-hidden"
                [classList]="imageCSS() + ' h-full w-full aspect-[608/342] object-cover'"
                [image]="{
                    image: image().url,
                    desktop: { width: 608, height: 342 },
                    tablet: { width: 487, height: 345 },
                    mobile: { width: 720, height: 404 },
                }"
                [priority]="isFirstView()"
                [alt]="image().alt || headline()"
                cover
            />
        </div>
        <div class="flex flex-col items-start justify-center px-12 pb-24 pt-16 md:p-56">
            <h3 class="text-34 leading-41 text-black md:mb-8">{{ headline() }}</h3>
            @if (text()) {
                <p class="text-16 leading-27 text-black">{{ text() }}</p>
            }
            @if (readMore() && readMoreText()) {
                <a
                    class="text-16 leading-27 text-black underline"
                    [hyundaiRouterLink]="readMore()"
                    (mouseover)="imageCSS.set('image-hover-static')"
                    (mouseleave)="imageCSS.set('image-hover-static-animation')"
                >
                    {{ readMoreText() }}
                </a>
            }
            @if (link() && linkText()) {
                <div class="mt-24 flex w-full justify-end md:mt-16">
                    <a
                        ui-m010-c020-icon-link
                        [hyundaiRouterLink]="link()"
                        (mouseover)="imageCSS.set('image-hover-static')"
                        (mouseleave)="imageCSS.set('image-hover-static-animation')"
                    >
                        {{ linkText() }}
                    </a>
                </div>
            }
        </div>
    </div>
</div>
