import { ChangeDetectionStrategy, Component, ViewEncapsulation, booleanAttribute, input } from '@angular/core';
import type { ApiTypes } from '@hyundai/ng-common-lib';
import { FootnotesPipe, RouterLinkDirective } from '@hyundai/ng-common-lib';
import { M010C010IconDoubleCtaComponent } from '../../../010-buttons-and-links/m010-c010-icon-double-cta/m010-c010-icon-double-cta.component';
import { M010C020IconLinkComponent } from '../../../010-buttons-and-links/m010-c020-icon-link/m010-c020-icon-link.component';
import { M040C010EnvkvComponent } from '../../../040-content-structure/m040-c010-envkv/m040-c010-envkv.component';
import type { M060C010VehicleShowroomButtonsData } from './m060-c010-vehicle-showroom-buttons.interface';

@Component({
    selector: 'ui-m060-c010-vehicle-showroom-buttons',
    standalone: true,
    templateUrl: './m060-c010-vehicle-showroom-buttons.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        M010C010IconDoubleCtaComponent,
        M010C020IconLinkComponent,
        RouterLinkDirective,
        M040C010EnvkvComponent,
        FootnotesPipe,
    ],
})
export class M060C010VehicleShowroomButtonsComponent {
    data = input.required<M060C010VehicleShowroomButtonsData[]>();

    ctaLink = input.required<string>();
    ctaText = input.required<string>();
    ctaSubText = input.required<string>();

    link = input.required<string>();
    linkText = input.required<string>();

    primaryButton = input.required<string>();
    primaryButtonText = input.required<string>();

    envkv = input<{ model: string; subheadline?: string; envkv: ApiTypes.Envkv; score: string }>();

    showOnlyPrimaryButton = input(false, { transform: booleanAttribute });
}
