<div class="flex flex-row items-center justify-between md:flex-col md:items-start md:justify-start">
    <div>
        <p class="leading-24 font-headline text-16 font-medium text-black">{{ dealer().name }}</p>
        <p class="leading-24 text-16 text-black">{{ dealer().street }}</p>
        <p class="leading-24 text-16 text-black">{{ dealer().zip }} {{ dealer().city }}</p>
    </div>

    @if (showAboutUsLink()) {
        <a
            ui-m010-c020-icon-link
            class="mt-24 hidden text-nowrap md:inline-flex"
            [hyundaiRouterLink]="dealer().pages.aboutUs"
        >
            Über uns
        </a>

        <a
            class="button-secondary w-max shrink-0 bg-white px-11 py-10 md:hidden"
            [hyundaiRouterLink]="dealer().pages.aboutUs"
        >
            Über uns
        </a>
    }
</div>
