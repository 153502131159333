<h3 class="text-16 leading-27 text-black">{{ text() }}</h3>
@for (opening of openings(); track $index; let first = $first) {
    <div class="grid grid-cols-2 gap-16" [ngClass]="{ 'mt-8 lg:mt-12': !first }">
        <p class="leading-21 text-14 font-medium text-black">{{ opening.fromToCombined }}:</p>
        <div>
            @for (range of opening.ranges; track $index) {
                <p class="leading-21 text-14 text-black">{{ range.start }} - {{ range.end }} Uhr</p>
            }
        </div>
    </div>
}
