<div class="inner-page-wrapper pb-40 pt-24 lg:pt-64">
    <div class="font-headline text-24 font-medium leading-30 lg:mb-40 lg:text-center lg:text-34 lg:leading-41">
        <ng-content select="[headline]" />
    </div>
    <div class="grid grid-cols-1 gap-x-40 lg:grid-cols-2 lg:gap-x-56">
        @for (warranty of warrantyList(); track $index; let first = $first; let last = $last) {
            <ui-m000-c010-image
                class="mx-auto aspect-[719/404] bg-grey-100 lg:order-1 lg:aspect-[580/326] [&:not(:first-child)]:mt-24 lg:[&:not(:first-child)]:mt-0"
                [alt]="warranty.image.alt || warranty.headline"
                [image]="{
                    image: warranty.image.url,
                    desktop: { width: 580, height: 326 },
                    tablet: { width: 719, height: 404 },
                    mobile: { width: 719, height: 404 },
                }"
                cover
                priority="false"
            />
            <div class="mt-24 pb-40 lg:order-2 lg:pb-40">
                <h5 class="mb-12 text-24 leading-30">
                    {{ warranty.headline }}
                </h5>
                <div class="text-16 leading-27">
                    {{ warranty.copy }}

                    <ul class="list-disc pl-24">
                        @for (item of warranty.list; track $index) {
                            <li [innerHTML]="item.text"></li>
                        }
                    </ul>
                </div>
                @if (warranty.downloadPdf) {
                    <a
                        ui-m010-c020-icon-link
                        class="mt-24"
                        [href]="warranty.downloadPdf"
                        target="_blank"
                        icon="icon-download"
                    >
                        Garantiebedingungen
                    </a>
                }

                @if (last && showMoreInfos()) {
                    <a
                        class="button-primary mt-24 flex lg:w-max"
                        [hyundaiRouterLink]="'https://www.hyundai.com/de/de/service/serviceleistungen/garantien.html'"
                        target="_blank"
                    >
                        Mehr zu unseren Garantien
                    </a>
                }
            </div>
        }
    </div>
</div>
