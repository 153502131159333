import { ChangeDetectionStrategy, Component, ViewEncapsulation, input } from '@angular/core';
import type { ApiTypes } from '@hyundai/ng-common-lib';
import { RouterLinkDirective } from '@hyundai/ng-common-lib';
import { M010C020IconLinkComponent } from '../../../010-buttons-and-links/m010-c020-icon-link/m010-c020-icon-link.component';

@Component({
    selector: 'ui-m030-c060-dealer-info',
    standalone: true,
    imports: [RouterLinkDirective, M010C020IconLinkComponent],
    templateUrl: './m030-c060-dealer-info.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class M030C060DealerInfoPersonComponent {
    dealer = input.required<ApiTypes.Dealer>();
    showAboutUsLink = input.required<boolean>();
}
