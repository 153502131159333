@if (car(); as c) {
    <div class="flex justify-center">
        <div
            class="relative -ml-[24px] -mr-[75px] block w-[100vw] transition-all xs:-ml-[35px] xs:-mr-[90px] md:-mx-[120px] md:w-[768px] lg:-mx-[80px] xl:-mx-48"
        >
            <ui-m000-c010-image
                class="block h-full"
                [classList]="'h-full w-full aspect-[768/433]'"
                [image]="{
                    image: c.image.url,
                    desktop: { width: 768, height: 433 },
                    tablet: { width: 768, height: 433 },
                    mobile: { width: 768, height: 433 },
                }"
                [priority]="isFirstView()"
                [alt]="c.image.alt || c.label"
                contain
                highRes
            />

            @if (c.badge) {
                <ui-m040-c030-badge
                    class="disable-on-not-active absolute left-48 top-16 block transition-opacity duration-500 xs:left-[59px] md:left-[100px] md:top-[50px]"
                    type="blue"
                >
                    {{ c.badge }}
                </ui-m040-c030-badge>
            }
        </div>
    </div>
}
