import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation, input } from '@angular/core';
import type { ApiTypes } from '@hyundai/ng-common-lib';

@Component({
    selector: 'ui-m030-c060-department-opening',
    standalone: true,
    imports: [NgClass],
    templateUrl: './m030-c060-department-opening.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class M030C060DepartmentOpeningComponent {
    text = input.required<string>();
    openings = input.required<ApiTypes.DealerDepartmentOpening>();
}
