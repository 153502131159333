<a class="group block w-full bg-transparent" [hyundaiRouterLink]="link()">
    <div class="relative">
        <ui-m000-c010-image
            class="block overflow-hidden"
            [classList]="'image-hover w-full aspect-[342/192] md:aspect-[313/207]'"
            [image]="{
                image: image().url,
                desktop: { width: 719, height: 404 },
                tablet: { width: 460, height: 304 },
                mobile: { width: 313, height: 207 },
            }"
            [priority]="isFirstView()"
            [alt]="image().alt || headline()"
            cover
        />
        @if (badge(); as data) {
            <ui-m040-c030-badge class="absolute left-12 top-12" type="blue">{{ data }}</ui-m040-c030-badge>
        }
    </div>
    <div class="flex justify-between gap-12 py-16 text-black" [ngClass]="flushContent() ? 'px-0' : 'px-12'">
        <div>
            @let headlineF = headline() | footnotes;
            <span class="text-16 leading-27" [innerHTML]="headlineF()"></span>
            @if (text(); as text) {
                @let textF = text | footnotes;
                <p class="text-14 leading-20" [innerHTML]="textF()"></p>
            }
        </div>

        <i class="icon-chevron-right size-24 self-start text-24 group-hover:animate-bouncingLeft"></i>
    </div>
</a>
