import { ChangeDetectionStrategy, Component, ViewEncapsulation, input, output } from '@angular/core';
import type { ApiTypes } from '@hyundai/ng-common-lib';
import { RouterLinkDirective } from '@hyundai/ng-common-lib';
import { M010C020IconLinkComponent } from '../../../010-buttons-and-links/m010-c020-icon-link/m010-c020-icon-link.component';

@Component({
    selector: 'ui-m030-c060-quick-links',
    standalone: true,
    imports: [M010C020IconLinkComponent, RouterLinkDirective],
    templateUrl: './m030-c060-quick-links.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class M030C060QuickLinksComponent {
    dealer = input.required<ApiTypes.Dealer>();
    contactLink = input.required<string>();

    contactClicked = output();
}
