<div class="bg-white px-24 py-16 md:hidden md:bg-grey-100" [ngClass]="{ 'pb-0': primaryButton() || secondaryButton() }">
    <div class="flex w-full justify-between gap-12">
        <ui-m000-c010-image
            class="block size-[80px] flex-shrink-0"
            [classList]="'w-full size-[80px] aspect-square rounded-full'"
            [image]="{
                image: contactPerson().image.url,
                desktop: { width: 80, height: 80 },
                tablet: { width: 80, height: 80 },
                mobile: { width: 80, height: 80 },
            }"
            [alt]="contactPerson().image.alt ?? contactPerson().name"
            [priority]="isFirstView()"
            cover
        />
        <div class="w-full font-medium text-black md:flex-shrink-0">
            <p class="font-headline text-16 leading-27">{{ contactPerson().name }}</p>
            <p class="-mt-4 text-16 leading-27">{{ contactPerson().position }}</p>
            <p class="leading-21 flex gap-4 text-14">
                <span>Unser Team ist für Sie da</span>
            </p>
            <p class="leading-21 text-14">
                <span class="capitalize">{{ dealer().openings.overallNextOpening }}</span>
                von {{ dealer().openings.overallRange?.start }} bis {{ dealer().openings.overallRange?.end }}
            </p>
        </div>
        <a
            class="max-h-40 max-w-40 xs:flex xs:w-full"
            [hyundaiRouterLink]="'tel:' + dealer().phone"
            [attr.aria-label]="'Kontakt via Telefon'"
        >
            <i class="icon-phone ml-auto text-[40px] text-accent"></i>
        </a>
    </div>

    @if (primaryButton() || secondaryButton()) {
        <div class="mt-8 flex justify-center gap-40">
            @if (secondaryButton(); as secondaryBtn) {
                <a class="inline-block py-16 font-headline text-16 text-accent" [hyundaiRouterLink]="secondaryBtn.link">
                    {{ secondaryBtn.label }}
                </a>
            }
            @if (primaryButton() && secondaryButton()) {
                <div class="my-auto h-[23px] w-1 bg-grey-400"></div>
            }
            @if (primaryButton(); as primaryBtn) {
                <a class="inline-block py-16 font-headline text-16 text-accent" [hyundaiRouterLink]="primaryBtn.link">
                    {{ primaryBtn.label }}
                </a>
            }
        </div>
    }
</div>
