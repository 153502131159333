import { ChangeDetectionStrategy, Component, ViewEncapsulation, booleanAttribute, input } from '@angular/core';
import { RouterLinkDirective } from '@hyundai/ng-common-lib';
import { M000C010ImageComponent } from '../../../000-primitives/m000-c010-image/m000-c010-image.component';
import { M010C020IconLinkComponent } from '../../../010-buttons-and-links/m010-c020-icon-link/m010-c020-icon-link.component';

@Component({
    selector: 'ui-m030-c060-desktop-contact-person',
    standalone: true,
    imports: [M000C010ImageComponent, RouterLinkDirective, M010C020IconLinkComponent],
    templateUrl: './m030-c060-desktop-contact-person.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class M030C060DesktopContactPersonComponent {
    contactPerson = input.required<{
        name: string;
        image: { url: string; alt?: string };
        position: string;
        phone: string;
    }>();
    phonenumber = input.required<string>();
    isFirstView = input.required({ transform: booleanAttribute });
}
