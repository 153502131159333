import { booleanAttribute, ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { RouterLinkDirective, type ApiTypes } from '@hyundai/ng-common-lib';
import { M000C010ImageComponent } from '../../000-primitives/m000-c010-image/m000-c010-image.component';
import { M010C020IconLinkComponent } from '../../010-buttons-and-links/m010-c020-icon-link/m010-c020-icon-link.component';

@Component({
    selector: 'ui-m030-c130-warrenty-list',
    standalone: true,
    templateUrl: './m030-c130-warrenty-list.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [M000C010ImageComponent, M010C020IconLinkComponent, RouterLinkDirective],
})
export class M030C130WarrentyListComponent {
    warrantyList = input<ApiTypes.WarrantyList>();
    showMoreInfos = input(false, { transform: booleanAttribute });
}
