import { NgClass } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    ViewEncapsulation,
    booleanAttribute,
    computed,
    input,
    output,
} from '@angular/core';
import type { ApiTypes } from '@hyundai/ng-common-lib';
import { RouterLinkDirective } from '@hyundai/ng-common-lib';
import { M000C010ImageComponent } from '../../000-primitives/m000-c010-image/m000-c010-image.component';
import { M040C050CardComponent } from '../../040-content-structure/m040-c050-card/m040-c050-card.component';
import { M030C060DealerInfoPersonComponent } from './m030-c060-dealer-info/m030-c060-dealer-info.component';
import { M030C060DepartmentOpeningComponent } from './m030-c060-department-opening/m030-c060-department-opening.component';
import { M030C060DesktopButtonsComponent } from './m030-c060-desktop-buttons/m030-c060-desktop-buttons.component';
import { M030C060DesktopContactPersonComponent } from './m030-c060-desktop-contact-person/m030-c060-desktop-contact-person.component';
import { M030C060MobileContactPersonComponent } from './m030-c060-mobile-contact-person/m030-c060-mobile-contact-person.component';
import { M030C060QuickLinksComponent } from './m030-c060-quick-links/m030-c060-quick-links.component';

@Component({
    selector: 'ui-m030-c060-global-contact',
    standalone: true,
    templateUrl: './m030-c060-global-contact.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        RouterLinkDirective,
        NgClass,
        M030C060DepartmentOpeningComponent,
        M030C060MobileContactPersonComponent,
        M030C060DealerInfoPersonComponent,
        M030C060DesktopContactPersonComponent,
        M030C060DesktopButtonsComponent,
        M030C060QuickLinksComponent,
        M000C010ImageComponent,
        M040C050CardComponent,
    ],
})
export class M030C060GlobalContactComponent {
    dealer = input.required<ApiTypes.Dealer>();
    isFirstView = input.required({ transform: booleanAttribute });
    contactLink = input.required<string>();
    contactPerson = input<{ name: string; image: { url: string; alt?: string }; position: string; phone: string }>();
    primaryButton = input<{ label: string; link: string }>();
    secondaryButton = input<{ label: string; link: string }>();
    showAboutUsLink = input(true, { transform: booleanAttribute });
    hasNgContent = input(false, { transform: booleanAttribute });
    darker = input(false, { transform: booleanAttribute });

    primaryButtonClicked = output();
    secondaryButtonClicked = output();
    contactClicked = output();

    locations = computed(() => {
        const dealer = this.dealer();
        return [
            ...(dealer.isSubDealer
                ? [
                      {
                          id: '0',
                          city: dealer.parentDealerAddress.city,
                          menuImage: dealer.parentMenuImage,
                          aboutUsUrl: dealer.pages.aboutUs,
                      },
                  ]
                : []),
            ...dealer.subDealers
                .filter((s) => s.id !== dealer.id)
                .map((subDealer) => ({
                    id: subDealer.id,
                    city: subDealer.city,
                    menuImage: subDealer.menuImage,
                    aboutUsUrl: subDealer.aboutUsUrl,
                })),
        ];
    });
}
