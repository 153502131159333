<a class="group block w-full bg-transparent" [hyundaiRouterLink]="data().link">
    <ui-m000-c010-image
        [classList]="'w-full aspect-[163/92] md:aspect-[262/148]'"
        [image]="{
            image: data().image.url,
            desktop: { width: 262, height: 148 },
            tablet: { width: 314, height: 178 },
            mobile: { width: 350, height: 198 },
        }"
        [priority]="isFirstView()"
        [alt]="data().image.alt || data().headline"
        cover
    />
    <div class="flex justify-between gap-12 pb-12 pt-8">
        <div>
            <span class="leading-21 font-text text-14 lg:text-16 lg:leading-27">
                {{ data().headline }}
            </span>
            @if (data().text) {
                <p class="leading-21 font-text text-14 lg:text-16 lg:leading-27">
                    {{ data().text }}
                </p>
            }
        </div>

        <i class="icon-chevron-right self-start text-24 text-accent group-hover:animate-bouncingLeft"></i>
    </div>
</a>
