<ui-m060-c010-vehicle-showroom-header [headline]="headline()" [subheadline]="subheadline()" />

<div class="m060-c010 relative">
    <div class="relative z-10">
        <div class="relative block">
            @if (cars().length > 1) {
                <ui-m060-c010-vehicle-showroom-tabs
                    [tabs]="tabs()"
                    [currentIndex]="slider.currentIndex()"
                    (tabClicked)="slider.slideToIndex($event)"
                />
                <ui-m060-c010-vehicle-showroom-slider
                    #slider
                    [cars]="cars()"
                    [isFirstView]="isFirstView()"
                    (emitCurrentTabIndex)="changeCurrentTabIndex($event)"
                />
            } @else {
                <ui-m060-c010-vehicle-showroom-item [car]="cars()[0]" [isFirstView]="isFirstView()" />
            }
        </div>
    </div>
    <div class="absolute bottom-0 left-0 h-[40%] w-full bg-grey-500 md:h-[175px]"></div>
</div>
@if (cars()[currentTabIndex()] && cars()[currentTabIndex()].showroomButtons) {
    <ui-m060-c010-vehicle-showroom-buttons
        [data]="cars()[currentTabIndex()].showroomButtons"
        [ctaLink]="ctaLink()"
        [ctaSubText]="ctaSubText()"
        [ctaText]="ctaText()"
        [link]="link()"
        [linkText]="linkText()"
        [primaryButton]="primaryButton()"
        [primaryButtonText]="primaryButtonText()"
        [showOnlyPrimaryButton]="showOnlyPrimaryButton()"
        [envkv]="envkv()"
    />
}
