<a
    class="group flex h-full gap-12 border-b-1 border-grey-200 px-16 pb-8 pt-16 md:px-0 md:pt-0"
    [hyundaiRouterLink]="link()"
>
    <i class="text-40 text-black" [ngClass]="[icon()]"></i>
    <div class="flex w-full flex-col items-start justify-between">
        <div class="mb-12">
            <h3 class="text-16 leading-27 text-black">{{ headline() }}</h3>
            <p class="leading-21 text-14 text-black">{{ text() }}</p>
        </div>
        <div class="flex w-full items-center justify-between gap-8">
            <div class="flex gap-16">
                @for (item of priceTags(); track $index) {
                    <div>
                        @if (item.headline) {
                            <p class="-mb-6 text-16 leading-27 text-black">{{ item.headline }}</p>
                        }
                        <p class="font-headline text-16 font-medium leading-27 text-black">{{ item.text }}</p>
                    </div>
                }
            </div>
            <i class="icon-chevron-right mr-12 size-24 text-24 text-accent group-hover:animate-bouncingLeft"></i>
        </div>
    </div>
</a>
