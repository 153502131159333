<div class="lg:max-w-auto mx-auto mt-24 hidden max-w-[500px] grid-cols-2 gap-24 md:grid lg:mt-[79px]">
    @if (secondaryButton(); as secondaryBtn) {
        <a
            class="inverted button-secondary bg-transparent"
            [hyundaiRouterLink]="secondaryBtn.link"
            (click)="secondaryButtonClicked.emit()"
        >
            {{ secondaryBtn.label }}
        </a>
    }
    @if (primaryButton(); as primaryBtn) {
        <a class="button-primary" [hyundaiRouterLink]="primaryBtn.link" (click)="primaryButtonClicked.emit()">
            {{ primaryBtn.label }}
        </a>
    }
</div>
