<div class="mb-12 hidden gap-12 md:flex">
    <ui-m000-c010-image
        class="block size-[72px] flex-shrink-0"
        [classList]="'w-full size-[72px] aspect-square rounded-full'"
        [image]="{
            image: contactPerson().image.url,
            desktop: { width: 72, height: 72 },
            tablet: { width: 72, height: 72 },
            mobile: { width: 72, height: 72 },
        }"
        [priority]="isFirstView()"
        [alt]="contactPerson().image.alt ?? contactPerson().name"
        highRes
        cover
    />
    <div>
        <p class="font-headline text-16 font-medium leading-27 text-black">{{ contactPerson().name }}</p>
        <p class="-mt-4 text-16 leading-27 text-black">{{ contactPerson().position }}</p>
        <a
            ui-m010-c020-icon-link
            class="inline-block"
            [hyundaiRouterLink]="'tel:' + phonenumber()"
            mobileLabelBelow
            icon="icon-phone"
        >
            {{ phonenumber() }}
        </a>
    </div>
</div>
