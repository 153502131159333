@if (cars(); as cars) {
    <ngx-px-carousel-awesome-plus
        [centered]="false"
        [forceCenter]="false"
        [arrowPrevTemplate]="prevArrow"
        [arrowNextTemplate]="nextArrow"
        sliderType="slider"
    >
        @for (car of cars; track car) {
            <ui-m060-c010-vehicle-showroom-item
                *ngxPxCarouselItem
                [car]="car"
                [isFirstView]="isFirstView() && $index <= 3"
            />
        }
    </ngx-px-carousel-awesome-plus>

    <ng-template #nextArrow>
        <i class="icon-slider-arrow hidden size-[40px] text-40 md:block" (click)="$event.preventDefault()"></i>
    </ng-template>

    <ng-template #prevArrow>
        <i
            class="icon-slider-arrow hidden size-[40px] rotate-180 transform text-[40px] md:block"
            (click)="$event.preventDefault()"
        ></i>
    </ng-template>
}
