import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation, booleanAttribute, input } from '@angular/core';
import type { Icon } from '@hyundai/assets';
import { RouterLinkDirective } from '@hyundai/ng-common-lib';

@Component({
    selector: 'ui-m040-c090-icon-text-card',
    standalone: true,
    templateUrl: './m040-c090-icon-text-card.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [RouterLinkDirective, NgClass],
})
export class M040C090IconTextCardComponent {
    headline = input.required<string>();
    text = input.required<string>();
    icon = input.required<Icon>();
    link = input.required<string>();
    priceTags = input.required<Array<{ headline?: string; text: string }>>();
    isFirstView = input.required({ transform: booleanAttribute });
}
