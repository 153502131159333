import { ChangeDetectionStrategy, Component, ViewEncapsulation, booleanAttribute, input } from '@angular/core';
import { RouterLinkDirective } from '@hyundai/ng-common-lib';
import { M000C010ImageComponent } from '../../000-primitives/m000-c010-image/m000-c010-image.component';
import type { M040C100ShopCard } from './m040-c100-shop-card.interface';

@Component({
    selector: 'ui-m040-c100-shop-card',
    standalone: true,
    templateUrl: './m040-c100-shop-card.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [RouterLinkDirective, M000C010ImageComponent],
})
export class M040C100ShopCardComponent {
    data = input.required<M040C100ShopCard>();
    isFirstView = input.required({ transform: booleanAttribute });
}
