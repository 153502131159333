import {
    ChangeDetectionStrategy,
    Component,
    ViewEncapsulation,
    afterNextRender,
    booleanAttribute,
    effect,
    inject,
    input,
    output,
    signal,
    untracked,
    viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import {
    NgxPxCarouselAwesomePlusComponent,
    NgxPxCarouselAwesomePlusItemDirective,
    NgxPxCarouselAwesomePlusPlaceholderComponent,
} from '@pixelgenau/ngx-px-carousel-awesome-plus';
import { Subject, combineLatest, delay } from 'rxjs';
import { M000C010ImageComponent } from '../../../000-primitives/m000-c010-image/m000-c010-image.component';
import { M040C030BadgeComponent } from '../../../040-content-structure/m040-c030-badge/m040-c030-badge.component';
import { M060C010VehicleShowroomItemComponent } from '../m060-c010-vehicle-showroom-item/m060-c010-vehicle-showroom-item.component';
import type { M060C010VehicleShowroom } from '../m060-c010-vehicle-showroom.interface';

@Component({
    selector: 'ui-m060-c010-vehicle-showroom-slider',
    standalone: true,
    templateUrl: './m060-c010-vehicle-showroom-slider.component.html',
    styleUrls: ['./m060-c010-vehicle-showroom-slider.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NgxPxCarouselAwesomePlusComponent,
        NgxPxCarouselAwesomePlusItemDirective,
        NgxPxCarouselAwesomePlusPlaceholderComponent,
        M000C010ImageComponent,
        M040C030BadgeComponent,
        M060C010VehicleShowroomSliderComponent,
        M060C010VehicleShowroomItemComponent,
    ],
})
export class M060C010VehicleShowroomSliderComponent {
    protected readonly activatedRoute = inject(ActivatedRoute);
    protected readonly router = inject(Router);

    cars = input.required<M060C010VehicleShowroom[]>();
    carousel = viewChild.required(NgxPxCarouselAwesomePlusComponent);
    currentIndex = signal(0);
    emitCurrentTabIndex = output<number>();
    activeParams$ = this.activatedRoute.queryParams;
    afterRender$ = new Subject<void>();
    isFirstView = input.required({ transform: booleanAttribute });

    constructor() {
        effect(() => {
            const currentIndex = this.carousel().currentIndex();
            untracked(() => {
                this.currentIndex.set(currentIndex);
                this.emitCurrentTabIndex.emit(currentIndex);
            });
        });

        combineLatest([this.activeParams$, this.afterRender$])
            .pipe(takeUntilDestroyed(), delay(0))
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .subscribe(([activeParams, _]) => {
                const car = activeParams['car'];
                if (!car) {
                    return;
                }
                const initIndex = this.getIndexFromCarsArr(car.toString());
                this.slideToIndex(initIndex);
            });

        afterNextRender(() => {
            this.afterRender$.next();
        });
    }

    getIndexFromCarsArr(value: string): number {
        return this.cars().findIndex((car) => car.label.toLocaleUpperCase() === value.toLocaleUpperCase());
    }

    slideToIndex(index: number): void {
        this.carousel().goToIndicatorIndex(index + 1);
    }
}
