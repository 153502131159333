import { ChangeDetectionStrategy, Component, ViewEncapsulation, booleanAttribute, input, signal } from '@angular/core';
import { RouterLinkDirective } from '@hyundai/ng-common-lib';
import { M000C010ImageComponent } from '../../000-primitives/m000-c010-image/m000-c010-image.component';
import { M010C020IconLinkComponent } from '../../010-buttons-and-links/m010-c020-icon-link/m010-c020-icon-link.component';
import { M040C030BadgeComponent } from '../m040-c030-badge/m040-c030-badge.component';

@Component({
    selector: 'ui-m040-c080-image-teaser',
    standalone: true,
    templateUrl: './m040-c080-image-teaser.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [RouterLinkDirective, M000C010ImageComponent, M010C020IconLinkComponent, M040C030BadgeComponent],
})
export class M040C080ImageTeaserComponent {
    headline = input.required<string>();
    image = input.required<{
        url: string;
        alt?: string | null;
    }>();
    text = input<string>();
    readMore = input<string>();
    readMoreText = input<string>();
    link = input<string>();
    linkText = input<string>();
    firstBadge = input<string>();
    secondBadge = input<string>();
    isFirstView = input.required({ transform: booleanAttribute });

    imageCSS = signal('image-hover-static-animation');
}
