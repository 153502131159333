<div class="inner-page-wrapper md:py-40">
    <div class="flex flex-col-reverse bg-grey-100 md:flex-row md:bg-white">
        <div
            class="relative"
            [ngClass]="{
                'hidden md:block': removeMobileImage(),
            }"
        >
            <ui-m000-c010-image
                class="block h-full"
                [classList]="'h-full w-full aspect-[767/430] md:aspect-[686/386] object-cover'"
                [image]="{
                    image: image().url,
                    desktop: { width: 686, height: 386 },
                    tablet: { width: 487, height: 345 },
                    mobile: { width: 767, height: 430 },
                }"
                [priority]="isFirstView()"
                [alt]="image().alt || headline()"
                cover
            />
        </div>
        <div class="flex w-full flex-col px-24 pb-40 pt-24 transition-all md:max-w-[390px] md:pb-24 lg:max-w-[530px]">
            <h3 class="text-20 leading-25 text-black md:text-34 md:leading-41">
                {{ headline() }}
            </h3>
            @let textF = text() | footnotes;
            <p class="text-16 leading-27 text-black md:mt-2" [innerHTML]="textF()"></p>
            <div
                class="mt-16 flex justify-between gap-12 border-b-1 border-black font-headline text-16 font-medium leading-27 text-black md:mt-12"
            >
                <span>{{ tableHeadline() }}</span>
                @let tableHeadlineValueF = tableHeadlineValue() || '' | footnotes;
                <span class="text-right" [innerHTML]="tableHeadlineValueF()"></span>
            </div>
            @for (item of tableData(); track $index) {
                <div class="mt-12 flex justify-between gap-12 font-text text-16 leading-27 text-black md:mt-4">
                    <span>{{ item.label }}</span>
                    <span class="text-right">{{ item.value }}</span>
                </div>
            }
            <div class="mt-[28px] justify-end md:mt-12 md:flex">
                <a class="button-secondary md:w-auto" [hyundaiRouterLink]="link()">
                    {{ linkText() }}
                </a>
            </div>
        </div>
    </div>
    @if (imageCaption(); as caption) {
        <p class="leading-21 mt-8 text-14 text-grey-400">{{ caption }}</p>
    }
</div>
