import {
    ChangeDetectionStrategy,
    Component,
    ViewEncapsulation,
    booleanAttribute,
    computed,
    input,
} from '@angular/core';
import { M040C060CardSmallComponent } from '../../040-content-structure/m040-c060-card-small/m040-c060-card-small.component';
import type { M004C060CardSmall } from '../../040-content-structure/m040-c060-card-small/m040-c060-card-small.interface';

@Component({
    selector: 'ui-m030-c070-card-small-wrapper',
    standalone: true,
    templateUrl: './m030-c070-card-small-wrapper.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [M040C060CardSmallComponent],
})
export class M030C070CardSmallWrapperComponent {
    cards = input.required<M004C060CardSmall[]>();
    forceShowButton = input(false, { transform: booleanAttribute });
    isFirstView = input.required({ transform: booleanAttribute });

    slicedCards = computed(() => {
        const cards = this.cards();
        return cards.slice(0, 4);
    });
}
