import {
    ChangeDetectionStrategy,
    Component,
    ViewEncapsulation,
    booleanAttribute,
    computed,
    input,
} from '@angular/core';
import { M040C100ShopCardComponent } from '../../040-content-structure/m040-c100-shop-card/m040-c100-shop-card.component';
import type { M040C100ShopCard } from '../../040-content-structure/m040-c100-shop-card/m040-c100-shop-card.interface';

@Component({
    selector: 'ui-m030-c030-shop-card-wrapper',
    standalone: true,
    templateUrl: './m030-c030-shop-card-wrapper.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [M040C100ShopCardComponent],
})
export class M040C040ShopCardWrapperComponent {
    cards = input.required<M040C100ShopCard[]>();
    forceShowButton = input(false, { transform: booleanAttribute });
    isFirstView = input.required({ transform: booleanAttribute });

    slicedCards = computed(() => {
        const cards = this.cards();
        return cards.slice(0, 4);
    });
}
