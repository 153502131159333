<div class="inner-page-wrapper pt-16 md:pt-40">
    @if (tabs(); as data) {
        @if (data.length > 1) {
            <div class="mt-16 flex gap-20 md:mt-24 md:justify-center md:gap-40" role="tablist">
                @for (item of data; track $index) {
                    <button
                        class="border-b-4 pb-4 font-headline text-14 leading-27 text-accent hover:font-medium md:text-18"
                        [ngClass]="{
                            'border-b-accent font-medium': currentIndex() === $index,
                            'border-b-transparent': currentIndex() !== $index,
                        }"
                        [attr.aria-selected]="currentIndex() === $index"
                        (click)="tabClicked.emit($index)"
                        role="tab"
                    >
                        {{ item }}
                    </button>
                }
            </div>
        }
    }
</div>
