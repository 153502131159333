import { ChangeDetectionStrategy, Component, ViewEncapsulation, input, output } from '@angular/core';
import { RouterLinkDirective } from '@hyundai/ng-common-lib';

@Component({
    selector: 'ui-m030-c060-desktop-buttons',
    standalone: true,
    imports: [RouterLinkDirective],
    templateUrl: './m030-c060-desktop-buttons.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class M030C060DesktopButtonsComponent {
    primaryButton = input<{ label: string; link: string }>();
    secondaryButton = input<{ label: string; link: string }>();

    primaryButtonClicked = output();
    secondaryButtonClicked = output();
}
