<div class="inner-page-wrapper flex flex-col items-center gap-24 py-40 md:gap-40">
    <div
        class="text-center font-headline text-20 font-medium leading-25 text-black empty:hidden md:text-34 md:leading-41"
    >
        <ng-content select="[headline]" />
    </div>

    <div class="grid grid-cols-2 gap-x-16 gap-y-24 md:grid-cols-4 lg:gap-[56px]">
        @for (item of slicedCards(); track $index) {
            <ui-m040-c100-shop-card [isFirstView]="isFirstView()" [data]="item" />
        }
    </div>

    <div class="empty:hidden">
        @if (forceShowButton() || cards().length > 4) {
            <ng-content select="[link]" />
        }
    </div>
</div>
